@import './variables.scss';

.toolbar {
  height: $toolbarHeight;
}

.rdrDayToday .rdrDayNumber span:after {
  background-color: #218364;
}

.summaryBox {
  width: 250px;
  min-height: 145px;
  padding: 20px;
  border-radius: $borderRadius;
}

.patientRow {
  box-shadow: none;
  color: var(--primary-main);
  background-color: var(--info-main);
  border-radius: $borderRadius;
  font-weight: 200;
}

.patientRowBold {
  font-weight: 500;
}

/** Investigate how to not use important for the selectedTab & tab classes **/

.selectedTab {
  background-color: $white !important;
  border-radius: $borderRadius !important;
}

.tab {
  color: var(--primary-main) !important;
}

.tabs {
  background-color: var(--secondary-main);
  border-radius: $borderRadius;
  padding: 10px;
}

.container {
  min-height: calc(100vh - $footerHeight);
  display: 'flex';
  flex-direction: 'column';
  overflow: 'hidden';
}